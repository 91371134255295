import React, { Component } from 'react';

import SEO from '../components/seo';

import PageContact from '../components/page-contact';
import PageCtaBoxes from '../components/page-cta-boxes';

class Page extends Component {
	render() {
		return (
			<>
				<SEO title="Thanks" />
				<PageContact submitted={true} />
				<PageCtaBoxes />
			</>
		);
	}
}

export default Page;